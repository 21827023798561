import Coupon from './Coupon';
import LicenseRenewal from './LicenseRenewal';
import ProtectionIcon from '../../Markup/assets/img/Shield-active New.svg';
import React, { useEffect, useMemo, useState } from 'react';
import CartInfo from './CartInfo';
import Upsell from './Upsell';
import Review from '../Affiliate/Review';
import FreeItem from './FreeItem';
import SplitPayment from './SplitPayment';

const RightSide = ({
	coupon,
	couponCode,
	setCouponCode,
	applyCoupon,
	showCoupon,
	setShowCoupon,
	couponError,
	couponRef,
	handleShowCouponClick,
	couponProcessing,
	addToCart,
	licenseRenewing,
	handleSubmit,
	cartData,
	cartLoading,
	removeFromCart,
	addToCartUpsell,
	total,
	processingCheckout,
	hidePaymentOptions,
	renewalDiscount,
	disablePaymentBtn,
	showUpsell,
}) => {
	const [licenseKey, setLicenseKey] = useState('');
	const [showFree, setShowFree] = useState(false);
	const [monthFree, setMonthFree] = useState(1);

	const agencyLifetime = useMemo(() => {
		if (!cartData) {
			return null;
		}

		return {
			index: cartData?.contents?.findIndex(item => +item.id === 474227),
			product: cartData?.contents?.find(item => +item.id === 474227),
		};
	}, [cartData]);

	useEffect(() => {
		if (cartData && cartData.details && cartData.details.length) {
			const [ea] = cartData.details.filter(
				item => parseInt(item.id) === 4372
			);
			if (ea) {
				setShowFree(true);
				switch (parseInt(ea.item_number?.options?.price_id)) {
					case 3:
						setMonthFree(3);
						break;
					case 4:
						setMonthFree(6);
						break;
					default:
						setMonthFree(1);
						break;
				}
			} else {
				const [eb] = cartData.details.filter(
					item => parseInt(item.id) === 1677666
				);
				if (eb) {
					setShowFree(true);
					switch (parseInt(eb.item_number?.options?.price_id)) {
						case 2:
							setMonthFree(3);
							break;
						case 3:
						case 4:
						case 5:
						case 6:
							setMonthFree(6);
							break;
						default:
							setMonthFree(1);
							break;
					}
				} else {
					setShowFree(false);
				}
			}
		}
	}, [cartData]);

	return (
		<div className="WpdCheckoutContentRight">
			<div className="WpdSectionTitleWrap">
				<h4 className="WpdCheckoutSubTitle">Order Details</h4>
			</div>
			<CartInfo
				cartData={cartData}
				cartLoading={cartLoading}
				removeFromCart={removeFromCart}
			/>
			{showUpsell && (
				<Upsell
					cartData={cartData}
					coupon={coupon}
					addToCartUpsell={addToCartUpsell}
					licenseKey={licenseKey}
					setLicenseKey={setLicenseKey}
					cartLoading={cartLoading}
				/>
			)}
			{showFree && !renewalDiscount && <FreeItem monthFree={monthFree} />}

			<SplitPayment
				coupon={coupon}
				cartData={cartData}
				addToCart={addToCart}
				cartLoading={cartLoading}
			/>

			<Coupon
				coupon={coupon}
				couponCode={couponCode}
				setCouponCode={setCouponCode}
				applyCoupon={applyCoupon}
				showCoupon={showCoupon}
				setShowCoupon={setShowCoupon}
				couponError={couponError}
				couponRef={couponRef}
				handleShowCouponClick={handleShowCouponClick}
				couponProcessing={couponProcessing}
				renewalDiscount={renewalDiscount}
				addToCart={addToCart}
			/>
			<LicenseRenewal
				addToCart={addToCart}
				licenseRenewing={licenseRenewing}
				cartData={cartData}
				licenseKey={licenseKey}
				setLicenseKey={setLicenseKey}
			/>

			<section className="WpdBuyerProtectionSection WpdSectionGap">
				<div className="WpdBuyerProtectionIcon">
					<img
						src={ProtectionIcon}
						alt="Protection"
					/>
				</div>
				<div className="WpdBuyerProtectionContent">
					<h4 className="WpdBuyerProtectionTitle">
						Buyer Protection
					</h4>
					<p className="WpdBuyerProtectionInfo">
						14 DAYS MONEY BACK GUARANTEE : if you are not 100%
						satisfied with our products, you will receive a 100%
						refund.
					</p>
				</div>
			</section>
			<button
				className="WpdPurchaseButton WpdSectionGap w-100"
				onClick={handleSubmit}
				disabled={disablePaymentBtn}
			>
				<span className="WpdText">
					{/* {disablePaymentBtn
						? 'Processing...'
						: hidePaymentOptions
							? 'Free Download'
							: `Pay $${total ? total.toFixed(2) : 0}`} */}

					{disablePaymentBtn && 'Processing...'}
					{!disablePaymentBtn &&
						hidePaymentOptions &&
						'Free Download'}
					{!disablePaymentBtn && !hidePaymentOptions && (
						<>
							Pay ${total ? total.toFixed(2) : 0}
							{agencyLifetime &&
								agencyLifetime?.product?.options?.recurring
									?.times && (
									<span
										className="d-block"
										style={{
											fontSize: '16px',
											textShadow: 'none',
											marginTop: '10px',
										}}
									>
										{`${agencyLifetime.product.options?.recurring?.times} months split payment applied`}
									</span>
								)}
						</>
					)}
				</span>
			</button>
			<Review />
		</div>
	);
};

export default RightSide;
